<template>
  <div>
    <el-carousel ref="carousel" trigger="click" :height="bannerHeight + 'px'" class="scroll_banners" @change="bannerChange" v-if="advertList && advertList.length !== 0">
      <el-carousel-item v-for="(item,index) in advertList" :key="item.id">
        <img :src="item.image" :ref="`bannerRef_${ index }`" v-if="item.href === ''">
        <a :href="item.href" v-if="item.href !== '' && item.mode===1" target="_blank" @click="updateAdvert(item.id)"><img @load="loadBanner" :ref="`bannerRef_${ index }`" :src="item.image"></a>
        <a :href="item.href" v-if="item.href !== '' && item.mode===2" target="" @click="updateAdvert(item.id)"><img @load="loadBanner" :ref="`bannerRef_${ index }`" :src="item.image"></a>
      </el-carousel-item>
    </el-carousel>
    <div class="feature">
      <div class="container_wrap">
        <el-row :gutter="40">
          <el-col :span="5">
            <div class="item">
              <i class="iconfont icon-icon-10 orange"></i>
              <h3>在线直播实时答疑</h3>
              <p>课后视频反复观看</p>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="item">
              <i class="iconfont icon-icon-11 green"></i>
              <h3>实战项目驱动</h3>
              <p>真实工业场景案例</p>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="item">
              <i class="iconfont icon-icon-12 red"></i>
              <h3>业界一线工程师</h3>
              <p>实战经验倾囊相授</p>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="item">
              <i class="iconfont icon-icon-13 blue"></i>
              <h3>实验平台辅助教学</h3>
              <p>告别繁杂的环境搭建</p>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="item">
              <i class="iconfont icon-icon-14 purple"></i>
              <h3>专属课程助教</h3>
              <p>为您高效学习保驾护航</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="content_wrap">
      <div class="container_wrap">
        <h1>免费课程</h1>
        <el-row :gutter="30">
          <el-col :span="6" v-for="(item, index) in freeCourse" :key="index">
            <el-card :body-style="{ padding: '0px' }" shadow="hover" class="card_item_common">
              <div class="cover" @click="goPathToNewPage('/course/detail/' + item.uuid)">
                <img :src="item.cover_image" class="image">
                <span class="hot" v-if="item.cover_image_label && item.cover_image_label != 'null'">{{ item.cover_image_label }}</span>
                <span class="tag" v-if="item.type">{{ item.type }}</span>
              </div>
              <div class="content">
                <div class="title text_ellipsis" @click="goPathToNewPage('/course/detail/' + item.uuid)">{{ item.name }}</div>
                <div class="price_wrap">
                  <el-tag size="small" type="danger" effect="dark" v-if="item.final_price == 0">免费</el-tag>
                  <span class="discount_price" v-else><span>¥</span>{{ item.final_price }}</span>
                  <span class="price" v-if="item.mark_price">¥ {{ item.mark_price }}</span>
                  <span class="info">
                    <span v-if="item.position"> {{ item.position }} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span v-if="item.start_at"> {{ item.start_at }}</span>
                    <span v-else-if="item.duration && item.total_seconds > 0">{{ item.duration }}</span>
                    <span v-if="item.experiment_len">{{ item.experiment_len }}</span>
                  </span>
                </div>
              </div>
              <div class="bottom">
                <span>
                  <i class="iconfont icon-icon_huaban1"></i>{{ item.view }}
                </span>
                <span>
                  <i class="iconfont icon-icon-02"></i>{{ item.like }}
                </span>
                <span>
                  <i class="iconfont icon-icon-03"></i>{{ item.favor }}
                </span>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <div class="btn_wrap">
          <el-button @click="goPathToNewPage('/course/list?price=free')">查看更多</el-button>
        </div>
        <h1>最新课程</h1>
        <el-row :gutter="30">
          <el-col :span="6" v-for="(item, index) in newCourse" :key="index">
            <el-card :body-style="{ padding: '0px' }" shadow="hover" class="card_item_common">
              <div class="cover" @click="goPathToNewPage('/course/detail/' + item.uuid)">
                <img :src="item.cover_image" class="image">
                <span class="hot" v-if="item.cover_image_label && item.cover_image_label != 'null'">{{ item.cover_image_label }}</span>
                <span class="tag" v-if="item.type">{{ item.type }}</span>
              </div>
              <div class="content">
                <div class="title text_ellipsis" @click="goPathToNewPage('/course/detail/' + item.uuid)">{{ item.name }}</div>
                <div class="price_wrap">
                  <el-tag size="small" type="danger" effect="dark" v-if="item.final_price == 0">免费</el-tag>
                  <span class="discount_price" v-else><span>¥</span>{{ item.final_price }}</span>
                  <span class="price" v-if="item.mark_price">¥ {{ item.mark_price }}</span>
                  <span class="info">
                    <span v-if="item.position"> {{ item.position }} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span v-if="item.start_at"> {{ item.start_at }}</span>
                    <span v-else-if="item.duration && item.total_seconds > 0">{{ item.duration }}</span>
                    <span v-if="item.experiment_len">{{ item.experiment_len }}</span>
                  </span>
                </div>
              </div>
              <div class="bottom">
                <span>
                  <i class="iconfont icon-icon_huaban1"></i>{{ item.view }}
                </span>
                <span>
                  <i class="iconfont icon-icon-02"></i>{{ item.like }}
                </span>
                <span>
                  <i class="iconfont icon-icon-03"></i>{{ item.favor }}
                </span>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <div class="btn_wrap">
          <el-button @click="goPathToNewPage('/course/list?sort=new')">查看更多</el-button>
        </div>
        <h1>讲师天团<span>也许是网络创新领域最强大的</span></h1>
        <el-row :gutter="30">
          <el-col :span="6" v-for="item in portrait" :key="item.id">
            <PortraitItem :item="item"></PortraitItem>
          </el-col>
        </el-row>
        <div class="btn_wrap">
          <el-button @click="goPath('/aboutus?isActiveName=课程讲师')">查看更多</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goPathToNewPage } from 'utils/common'
import { listUserCur } from "api/course/course/list"
import { listTeacher, get_favor_like } from "api/course/course/create"
import PortraitItem from 'components/PortraitItem'
import { getAdvertList, updateHitNum } from 'api/advert/admin'

  export default {
    components: {
      PortraitItem
    },
    data() {
      return {
        freeCourse: [],
        newCourse: [],
        portrait: [],
        advertList: [],
        bannerIndex: 0,
        bannerHeight: 500,
      }
    },
    mounted() {
      const that = this;
      this.getAdvertList()
      this.listFreeCur()
      this.listNewCur()
      this.getTeacherList()
      window.addEventListener('resize', this.setBannerHeight)
    },
    unmounted() {
      window.removeEventListener('resize', this.setBannerHeight)
    },
    methods: {
      goPathToNewPage,
      setBannerHeight() {
        if(this.advertList.length !== 0) {
          this.bannerHeight = this.$refs[`bannerRef_${this.bannerIndex}`].height;
        }
      },
      loadBanner() {
        this.bannerHeight = this.$refs['bannerRef_0'].height;
      },
      bannerChange(index) {
        this.bannerIndex = index;
        this.bannerHeight = this.$refs[`bannerRef_${ index }`].height;
      },
      getFavorLike(courseItem){
        courseItem.forEach(item => {
          get_favor_like(item.uuid).then(res => {
            item.like = res.like.num
            item.favor = res.favor.num
            item.view = res.view_num
          })
        })
      },
      listFreeCur() {
        let data = {
          final_price: 0,
          state: 3,
          need_count: false,
          limit: 4,
          offset: 0,
          need_info: true,
          need_type: true
        }
        listUserCur(data).then(res => {
          this.freeCourse = res.data
          this.getFavorLike(this.freeCourse)
        })
      },
      listNewCur() {
        let data = {
          final_price: '>0',
          sort_key: 'publish_at',
          state: 3,
          need_count: false,
          limit: 4,
          offset: 0,
          need_info: true,
          need_type: true
        }
        listUserCur(data).then(res => {
          this.newCourse = res.data
          this.getFavorLike(this.newCourse)
        })
      },
      getTeacherList() {
        const that = this
        var filters = {
          limit: 4,
          offset: 0
        }
        listTeacher(filters).then(res => {
          that.portrait = res.data
          console.log(that.portrait)
        })
      },
      getAdvertList() {
        const that = this
        let filters = {
          location: 0,
          state:  1,
          order: true
        }
        getAdvertList(filters).then(res => {
          that.advertList = res.data
          console.log(that.advertList)
        })
      },
      updateAdvert(id) {
        let data = new FormData();
        data.append('up_hit_num', '1');
        updateHitNum(id, data).then(() => {
        })
      },
      goPath(path) {
        this.$router.push(path);
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import 'assets/font/font.scss';
  :deep(.scroll_banners) {
    margin-top:-20px;
    margin-left:-20px;
    margin-right:-20px;
    background:#f1f1f1;
    img {
      width:100%;
    }
  }
  .feature {
    padding-top:30px;
    padding-bottom:30px;
    .el-col-5 {
      max-width: 20%;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
    }
    .item {
      padding:40px 0 50px 0;
      background:#f5f6f6;
      border-radius:20px 0 20px 20px;
      text-align: center;
      > i {
        display:block;
        font-size:52px;
        margin-bottom:30px;
        &.orange {
          color:#fda83b;
        }
        &.green {
          color:#77bd77;
        }
        &.red {
          color:#f15a51;
        }
        &.blue {
          color:#009dd9;
        }
        &.purple {
          color:#7453bc;
        }
      }
      h3 {
        color:#333;
        font-size:18px;
        margin-top:0;
        margin-bottom:20px;
      }
      p {
        margin:0;
      }
    }
  }
  .content_wrap {
    background:#f8f8f8;
    padding-top:80px;
    margin-left:-20px;
    margin-right:-20px;
    margin-bottom:-20px;
    h1 {
      font-size:34px;
      color:#333;
      margin-top:0;
      margin-bottom:50px;
      text-align:center;
      font-family: 'font-pingfang-bold';
      > span {
        display:block;
        font-size:18px;
        color:#999;
        margin-top:20px;
      }
    }
    .btn_wrap {
      padding-top:30px;
      padding-bottom:80px;
      text-align:center;
      .el-button {
        padding:20px 80px;
        background:#dfeff5;
        border:none;
        color:#009dd9;
        &:hover {
          background:#009dd9;
          color:#fff;
        }
      }
    }
  }
</style>
