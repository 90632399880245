<template>
  <div class="portrait_item" @mouseenter="isShow = true" @mouseleave="isShow = false">
    <img :src="item.picture" />
    <div class="content" :style="isShow ? 'height:100%;' : 'height:60px;'">
      <div class="head">
        <span class="name text_ellipsis" :title="item.name">{{ item.name }}</span>
        <span class="job text_ellipsis" :title="item.title">{{ item.title }}</span>
      </div>
      <div class="intro">
        {{ item.profile }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: Object
    },
    data() {
      return {
        isShow: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .portrait_item {
    position:relative;
    overflow:hidden;
    margin-bottom:20px;
    height:360px;
    background-image: linear-gradient(to bottom, #FFFFFF , #B8EDFF);
    > img {
      width:200px;
      height:200px;
      display: block;
      margin: 50px auto 0;
    }
    .content {
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      background:rgba(0, 157, 217, 0.9);
      position:absolute;
      left:0;
      bottom:0;
      right:0;
      color:#fff;
      display:flex;
      flex-flow: column;
      height:100%;
      .head {
        display:flex;
        padding:20px;
        align-items: center;
        > span {
          &.name {
            font-size:18px;
            font-weight: bold;
            margin-right:20px;
            max-width:50%;
          }
          &.job {
            flex:1;
          }
        }
      }
      .intro {
        padding:0 20px 20px 20px;
        font-size:12px;
        overflow:hidden;
        line-height:2;
        flex:1;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }
</style>
