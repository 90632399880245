import { fetch, post, del, postForm } from 'api/base/axios'

const URL = {
  advertListUrl: '/imgShow/list/',
  createAdvertUrl: '/imgShow/create/',
  updateAdvertUrl: '/imgShow/update/',
  delAdvertUrl: '/imgShow/delete/',
  batchAdvertUrl: '/imgShow/batch/',
  updateHitNumUrl: '/imgShow/update_hit_num/',
}

export function getAdvertList (data) {
  return fetch(URL.advertListUrl, data)
}
export function createAdvert (data) {
  return postForm(URL.createAdvertUrl, data)
}
export function updateAdvert (advert_id, form) {
  return post(URL.updateAdvertUrl + advert_id , form)
}
export function delAdvert (form) {
  return fetch(URL.delAdvertUrl, form)
}
export function batchAdvert (form) {
  return post(URL.batchAdvertUrl, form)
}
export function updateHitNum (advert_id, form) {
  return post(URL.updateHitNumUrl + advert_id , form)
}
